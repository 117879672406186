<template>
  <div>
    <div class="account">
      <div class="title">
        <div class="title-txt d-flex align-center justify-center">账户管理</div>
      </div>
      <ul class="list">
        <li class="item d-flex align-center">
          <div class="item-title d-flex align-center">
            <img src="../../assets/img/account_yes.png" alt="" /> 个人资料
          </div>
          <div class="item-name">{{ user.rname }}</div>
          <div
            class="item-btn d-flex align-center cursor"
            @click="selectItem(1)"
          >
            编辑 <img src="../../assets/img/account_a.png" alt="" />
          </div>
        </li>
        <personal v-show="index == 1 && isShow"></personal>
        <li class="item d-flex align-center">
          <div class="item-title d-flex align-center">
            <img src="../../assets/img/account_yes.png" alt="" />
            用户头像
          </div>
          <div class="item-img">
            <img v-if="user.icon && user.icon != ''" :src="user.icon" alt="" />
            <img v-else src="../../assets/img/ava.png" alt="" />
          </div>
          <div class="prompt">赶快设置属于自己的头像吧~</div>
          <div
            class="item-btn d-flex align-center cursor"
            @click="selectItem(2)"
          >
            编辑 <img src="../../assets/img/account_a.png" alt="" />
          </div>
        </li>
        <portrait v-show="index == 2 && isShow"></portrait>
        <li class="item d-flex align-center">
          <div class="item-title d-flex align-center">
            <img src="../../assets/img/account_yes.png" alt="" /> 账户密码
          </div>
          <div class="item-name"></div>
          <div
            class="item-btn d-flex align-center cursor"
            @click="selectItem(3)"
          >
            编辑 <img src="../../assets/img/account_a.png" alt="" />
          </div>
        </li>
        <password v-show="index == 3 && isShow"></password>
        <li class="item d-flex align-center" v-if="false">
          <div class="item-title d-flex align-center">
            <img src="../../assets/img/account_yes.png" alt="" /> 绑定手机
          </div>
          <div class="item-name">{{ user.phone }}</div>
          <div class="prompt">
            若手机更换或停用，请立即修改，避免账户存在风险
          </div>
          <div
            class="item-btn d-flex align-center cursor"
            @click="selectItem(4)"
          >
            编辑 <img src="../../assets/img/account_a.png" alt="" />
          </div>
        </li>
        <phonetype v-show="index == 4 && isShow"></phonetype>
      </ul>
    </div>
  </div>
</template>

<script>
import personal from "./components/personal";
import password from "./components/password";
import phonetype from "./components/phonetype";
import portrait from "./components/portrait";
export default {
  data() {
    return {
      index: 1,
      isShow: true,
      user: ""
    };
  },
  components: {
    personal,
    password,
    phonetype,
    portrait
  },
  mounted() {
    let user = this.$store.state.user;
    let users = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.isSHow = true;
      this.user = user;
    } else {
      this.isSHow = true;
      this.user = users;
    }
  },
  methods: {
    selectItem(index) {
      console.log(this.index, index, this.isShow);
      if (this.index == index) {
        this.isShow = !this.isShow;
        this.index = index;
      } else {
        if (this.isShow) {
          this.index = index;
        } else {
          this.isShow = !this.isShow;
          this.index = index;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.account {
  min-height: 680px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 0 24px;
  box-sizing: border-box;
  .title {
    height: 63px;
    border-bottom: 3px solid #eeeeee;
    .title-txt {
      height: 63px;
      width: 130px;
      font-size: 22px;
      font-weight: bold;
      color: #5f2eff;
      border-bottom: 3px solid #5f2eff;
    }
  }
  .list {
    padding: 0 23px;
    padding-bottom: 40px;
    box-sizing: border-box;
    .item {
      height: 58px;
      border-top: 1px solid #eeeeee;
      &:first-child {
        border-top: 0;
      }
      .item-title {
        width: 163px;
        font-size: 14px;
        color: #888888;
        img {
          height: 27px;
          width: 27px;
          margin-right: 9px;
        }
      }
      .item-name {
        font-size: 16px;
        color: #444444;
      }
      .item-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
      }
      .prompt {
        font-size: 12px;
        color: #888888;
        margin-left: 10px;
      }
      .item-btn {
        margin-left: auto;
        font-size: 12px;
        color: #666666;
        img {
          width: 14px;
          height: 12px;
          margin-left: 8px;
        }
      }
    }
  }
}
</style>
