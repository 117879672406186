<template>
  <div class="personal">
    <div class="item d-flex align-center">
      <div class="item-tite">登录名称：</div>
      <div class="item-name">{{ user.rname }}</div>
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">性别：</div>
      <el-radio-group v-model="radio" @change="selectRadio">
        <el-radio :label="1">男</el-radio>
        <el-radio :label="2">女</el-radio>
        <el-radio :label="0">保密</el-radio>
      </el-radio-group>
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">生日：</div>
      <el-select
        v-model="yearsModel"
        @change="dateChange(1)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in years"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="monthsModel"
        @change="dateChange(2)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in months"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="daysModel"
        @change="dateChange(3)"
        placeholder="请选择"
      >
        <el-option
          v-for="item in days"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">所在地：</div>
      <el-select
        v-model="cityModel"
        @change="cityChange()"
        placeholder="请选择"
      >
        <el-option
          v-for="item in city"
          :key="item.id"
          :label="item.region_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="citysModel"
        @change="areaChange()"
        placeholder="请选择"
      >
        <el-option
          v-for="item in citys"
          :key="item.id"
          :label="item.region_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select v-model="areaModel" placeholder="请选择">
        <el-option
          v-for="item in area"
          :key="item.id"
          :label="item.region_name"
          :value="item.id"
        >
        </el-option>
      </el-select>
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite"><span>*</span>真实姓名：</div>
      <input
        type="text"
        ref="name"
        v-model="name"
        placeholder="请输入您的真实姓名"
      />
    </div>
    <div class="btn d-flex align-center justify-center cursor" @click="submit">
      保存
    </div>
    <div class="item-bottom">“<span>*</span>”为必填项</div>
  </div>
</template>

<script>
import city from "../../../utils/city";
export default {
  data() {
    return {
      radio: 1,
      yearsModel: null,
      years: [],
      monthsModel: null,
      months: [],
      daysModel: null,
      days: [],
      city: [],
      cityModel: null,
      citys: [],
      citysModel: null,
      area: [],
      areaModel: null,
      user: "",
      name: "",
      sex: ""
    };
  },
  created() {
    this.init();
    this.city = city.city;
    console.log(city.city);
  },
  mounted() {
    this.getInit();
    let user = this.$store.state.user;
    let users = JSON.parse(window.localStorage.getItem("user"));
    if (user) {
      this.isSHow = true;
      this.user = user;
    } else {
      this.isSHow = true;
      this.user = users;
    }
  },
  methods: {
    selectRadio(value) {
      console.log(value);
      this.sex = value;
    },
    getInit() {
      let _this = this;
      _this.$api
        .queryUser({})
        .then(res => {
          console.log(res);
          if (
            res.data.area != 0 &&
            res.data.province != 0 &&
            res.data.city != 0
          ) {
            let id = res.data.province;
            let city = _this.city;
            let ids = res.data.city;
            for (let i in city) {
              if (city[i].id == id) {
                _this.citys = city[i].children;
                for (let j in city[i].children) {
                  if (city[i].children[j].id == ids) {
                    _this.area = city[i].children[j].children;
                  }
                }
              }
            }
            _this.cityModel = res.data.province;
            _this.citysModel = res.data.city;
            _this.areaModel = res.data.area;
          }
          if (
            res.data.birYear != "" &&
            res.data.birMonth != "" &&
            res.data.birDay != ""
          ) {
            _this.yearsModel = res.data.birYear;
            _this.monthsModel = res.data.birMonth;
            _this.daysModel = res.data.birDay;
          }
          _this.radio = res.data.sex;
          _this.sex = res.data.sex;
          _this.name = res.data.rname;
        })
        .catch(err => {
          console.log(err.msg);
        });
    },
    async getUpdate(params) {
      let _this = this;
      let data = {
        rname: _this.name,
        sex: _this.sex,
        birYear: _this.yearsModel,
        birMonth: _this.monthsModel,
        birDay: _this.daysModel,
        province: _this.cityModel,
        city: _this.citysModel,
        area: _this.areaModel
      }
      const res = await this.$ajaxRequest('put', 'update', data)
      console.log(res);
      this.$message({
        message: res.msg,
        type: "success"
      });
      let user = this.$store.state.user;
      console.log(user);
      user.rname = _this.name;
      this.$store.commit("userInfo", user);
      _this.getSms();
    },

    submit() {
      let _this = this;
      if (_this.name == "") {
        this.$message({
          message: "请填写真实姓名",
          type: "error"
        });
      } else {
        _this.getUpdate()
      }
    },
    init() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取当前年
      var month = myDate.getMonth() + 1; //获取当前月
      var day = myDate.getDate(); //获取当前日

      this.initSelectYear(year);
      this.initSelectMonth();
      this.initSelectDay(year, month);
      this.yearsModel = year;
      this.monthsModel = month;
      this.daysModel = day;
    },
    initSelectYear(year) {
      this.years = [];
      for (let i = 0; i < 30; i++) {
        this.years.push({ value: year - i, label: year - i });
      }
    },
    initSelectMonth() {
      this.months = [];
      for (let i = 1; i <= 12; i++) {
        this.months.push({ value: i, label: i });
      }
    },
    initSelectDay(year, month) {
      var maxDay = this.getMaxDay(year, month);
      this.days = [];
      for (var i = 1; i <= maxDay; i++) {
        this.days.push({ value: i, label: i });
      }
    },
    dateChange(type) {
      //1年 2月 3日 4 左 5右
      if (type == 1 || type == 2) {
        if (this.monthsModel == 0) {
          this.daysModel = 0;
          this.initSelectDay(this.yearsModel, 1);
        } else {
          this.initSelectDay(this.yearsModel, this.monthsModel);
        }
      }
      if (type == 2) {
        this.daysModel = this.days[0].value;
      }
      console.log(this.yearsModel, this.monthsModel, this.daysModel);
      //操作父组件方法
      // let obj = { year: this.yearsModel, month: this.monthsModel, day: this.daysModel }
      // this.$parent.dateChange(obj);
    },
    getMaxDay(year, month) {
      var new_year = year; //取当前的年份
      var new_month = month++; //取下一个月的第一天，方便计算（最后一天不固定）
      if (month > 12) {
        //如果当前大于12月，则年份转到下一年
        new_month -= 12; //月份减
        new_year++; //年份增
      }
      var new_date = new Date(new_year, new_month, 1); //取当年当月中的第一天
      return new Date(new_date.getTime() - 1000 * 60 * 60 * 24).getDate(); //获取当月最后一天日期
    },
    cityChange() {
      // console.log(index);
      let _this = this;
      let id = _this.cityModel;
      let city = _this.city;
      for (let i in city) {
        if (city[i].id == id) {
          _this.citys = city[i].children;
          _this.citysModel = city[i].children[0].id;
        }
      }
    },
    areaChange() {
      let _this = this;
      let id = _this.cityModel;
      let city = _this.city;
      let ids = _this.citysModel;
      for (let i in city) {
        if (city[i].id == id) {
          _this.citys = city[i].children;
          for (let j in city[i].children) {
            if (city[i].children[j].id == ids) {
              _this.area = city[i].children[j].children;
              _this.areaModel = city[i].children[j].children[0].id;
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.personal {
  height: 450px;
  background: #fbfbfb;
  border-top: 1px solid #5f2fff;
  padding: 30px 0;
  box-sizing: border-box;
  .item {
    width: 530px;
    height: 48px;
    background: #e0e0e0;
    box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
    border-radius: 4px;
    margin: 0 auto;
    padding-left: 50px;
    box-sizing: border-box;
    .item-tite {
      width: 80px;
      font-size: 14px;
      color: #333333;
    }
    .item-name {
      font-size: 14px;
      color: #333333;
    }
  }
  .item-box {
    width: 530px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
    border-radius: 4px;
    margin: 0 auto;
    padding-left: 50px;
    box-sizing: border-box;
    margin-top: 14px;
    .item-tite {
      width: 80px;
      font-size: 13px;
      color: #999999;
      span {
        color: #ea4f29;
        font-size: 13px;
      }
    }
  }
  .btn {
    width: 528px;
    height: 48px;
    background-image: linear-gradient(180deg, #406bff 0%, #5f2eff 100%);
    border-radius: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 16px;
    color: #fafbfd;
  }
  .item-bottom {
    width: 528px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 13px;
    color: #999999;
    padding-left: 21px;
    span {
      color: #ea4f29;
      font-size: 13px;
    }
  }
}
</style>
