<template>
  <div class="phonetype">
    <div class="item-top">
      <span>*</span>
      重新绑定手机请验证您的用户信息
    </div>
    <div class="item d-flex align-center">
      <div class="item-tite">原手机号码：</div>
      <div class="item-name">{{ user.phone }}</div>
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">
        <img src="../../../assets/img/login_2.png" alt="" />
      </div>
      <input
        type="password"
        ref="password"
        v-model="password"
        placeholder="请输入当前登录密码"
      />
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">
        <img src="../../../assets/img/login_2.png" alt="" />
      </div>
      <input
        type="text"
        ref="phone"
        v-model="phone"
        placeholder="请输入您要新绑定的手机号码"
      />
    </div>
    <div class="item-code d-flex align-center justify-between">
      <div class="input d-flex align-center">
        <div class="item-tite">
          <img src="../../../assets/img/login_2.png" alt="" />
        </div>
        <input
          type="text"
          ref="code"
          v-model="code"
          placeholder="请输入短信验证码"
        />
      </div>
      <div
        class="code d-flex align-center justify-center cursor"
        @click="sendCodeNotImgCode"
        v-if="shows"
      >
        发送短信验证码
      </div>
      <div
        class="code d-flex align-center justify-center cursor"
        @click="sendCodeNotImgCode"
        v-if="!shows"
      >
        {{ counts }}后重新获取
      </div>
    </div>
    <div
      class="btn d-flex align-center justify-center cursor"
      @click="submit()"
    >
      重新绑定
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      phone: "",
      code: "",
      user: "",
      shows: true,
      counts: "",
      timers: null
    };
  },
  mounted() {
    const user = JSON.parse(window.localStorage.getItem("user"));
    this.user = user;
  },
  methods: {
    //发送注册验证码
    sendCodeNotImgCode() {
      let _this = this;
      let phone = _this.$refs.phone.value;
      if (phone != "") {
        if (!/^1[3456789]\d{9}$/.test(phone)) {
          this.$message({
            message: "请输入正确手机号",
            type: "error"
          });
        } else {
          _this.$api
            .sendCodeNotImgCode({
              phone: phone
            })
            .then(res => {
              this.$message({
                message: res.message,
                type: "success"
              });
              _this.getSms();
            })
            .catch(err => {
              console.log(err.msg);
            });
        }
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
      }
    },
    submit() {
      let _this = this;
      let password = _this.$refs.password.value;
      let phone = _this.$refs.phone.value;
      let code = _this.$refs.code.value;
      if (password == "") {
        this.$message({
          message: "请输入密码",
          type: "error"
        });
      } else if (phone == "") {
        this.$message({
          message: "请输入手机号",
          type: "error"
        });
      } else if (code == "") {
        this.$message({
          message: "请输入短信验证码",
          type: "error"
        });
      } else {
        _this.$api
          .phone({
            upass: password,
            phone: phone,
            smsCode: code
          })
          .then(res => {
            if (res.code == 0) {
              let user = this.$store.state.user;
              console.log(user);
              user.phone = phone;
              this.$store.commit("userInfo", user);
              _this.password = "";
              _this.phone = "";
              _this.code = "";
            }
            this.$message({
              message: res.message,
              type: "success"
            });
          })
          .catch(err => {
            console.log(err.msg);
          });
      }
    },
    // 注册获取短信验证码时间
    getSms() {
      let _this = this;
      // if (_this.iShow) {
      const TIME_COUNT = 60;
      if (!_this.timers) {
        _this.counts = TIME_COUNT;
        _this.shows = false;
        _this.timers = setInterval(() => {
          if (_this.counts > 0 && _this.counts <= TIME_COUNT) {
            _this.counts--;
          } else {
            _this.shows = true;
            clearInterval(_this.timers);
            _this.timers = null;
          }
        }, 1000);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.phonetype {
  height: 440px;
  background: #fbfbfb;
  border-top: 1px solid #5f2fff;
  padding: 30px 0;
  box-sizing: border-box;
  .item {
    width: 530px;
    height: 48px;
    background: #e0e0e0;
    box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
    border-radius: 4px;
    margin: 0 auto;
    padding-left: 31px;
    box-sizing: border-box;
    .item-tite {
      // width: 80px;
      font-size: 14px;
      color: #333333;
    }
    .item-name {
      font-size: 14px;
      color: #333333;
    }
  }
  .item-box {
    width: 530px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
    border-radius: 4px;
    margin: 0 auto;
    padding-left: 31px;
    box-sizing: border-box;
    margin-top: 14px;
    .item-tite {
      width: 18px;
      height: 20px;
      margin-right: 11px;
    }
  }
  .item-code {
    width: 530px;
    height: 48px;
    margin: 0 auto;
    margin-top: 14px;
    .input {
      width: 400px;
      height: 48px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
      border-radius: 4px;
      padding-left: 31px;
      box-sizing: border-box;
      .item-tite {
        width: 18px;
        height: 20px;
        margin-right: 11px;
      }
    }
    .code {
      width: 122px;
      height: 46px;
      background: #f7f9ff;
      border: 1px solid #5f2eff;
      border-radius: 4px;
      font-size: 14px;
      color: #5f2eff;
    }
  }
  .btn {
    width: 528px;
    height: 48px;
    background-image: linear-gradient(180deg, #406bff 0%, #5f2eff 100%);
    border-radius: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 16px;
    color: #fafbfd;
  }
  .item-top {
    width: 528px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-bottom: 16px;
    font-size: 13px;
    color: #999999;
    // padding-left: 21px;
    span {
      color: #ea4f29;
      font-size: 13px;
    }
  }
  .item-bottom {
    width: 528px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 13px;
    color: #999999;
    padding-left: 21px;
    span {
      color: #ea4f29;
      font-size: 13px;
    }
  }
}
</style>
