<template>
  <div class="password">
    <div class="item-box d-flex align-center">
      <div class="item-tite">
        <img src="../../../assets/img/login_2.png" alt="" />
      </div>
      <input
        type="password"
        ref="password"
        v-model="password"
        placeholder="请输入原始密码"
      />
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">
        <img src="../../../assets/img/login_2.png" alt="" />
      </div>
      <input
        type="password"
        ref="newpassword"
        v-model="newpassword"
        placeholder="请输入新密码"
      />
    </div>
    <div class="item-box d-flex align-center">
      <div class="item-tite">
        <img src="../../../assets/img/login_2.png" alt="" />
      </div>
      <input
        type="password"
        ref="quepassword"
        v-model="quepassword"
        placeholder="请再次确认新密码"
      />
    </div>
    <div
      class="btn d-flex align-center justify-center cursor"
      @click="submit()"
    >
      确认提交
    </div>
    <div class="item-bottom">
      <span>*</span>
      1.为保证您的账户安全，新密码必须与旧密码不同。
    </div>
    <div class="item-bottom">
      <span>*</span>
      2.密码由6-20位字符（字母、数字、符号）任二种组合而成。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password: "",
      newpassword: "",
      quepassword: ""
    };
  },
  methods: {
    async updateUpass(data) {
      let _this = this;
      const res = await this.$ajaxRequest('put', 'updateUpass', data)
      console.log(res);
      if (res.code == 200) {
        this.$message({
          message: res.data + ',请重新登录',
          type: "success"
        });
        _this.password = "";
        _this.newpassword = "";
        _this.quepassword = "";
        window.localStorage.clear();
        _this.$router.replace("/login");
      } else {
        this.$message({
          message: res.data,
          type: "success"
        });
      }

    },
    submit() {
      let _this = this;
      let password = this.$refs.password.value;
      let newpassword = this.$refs.newpassword.value;
      let quepassword = this.$refs.quepassword.value;
      if (
        newpassword != "" &&
        quepassword != "" &&
        newpassword == quepassword
      ) {
        let data = {
          oldPass: password,
          pass: newpassword,
          passAgain: quepassword
        }
        _this.updateUpass(data)
      } else {
        this.$message({
          message: "请输入密码",
          type: "success"
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.password {
  height: 370px;
  background: #fbfbfb;
  border-top: 1px solid #5f2fff;
  padding: 30px 0;
  box-sizing: border-box;
  .item-box {
    width: 530px;
    height: 48px;
    background: #ffffff;
    box-shadow: 0px 2px 4px 0px rgba(197, 197, 197, 0.36);
    border-radius: 4px;
    margin: 0 auto;
    padding-left: 31px;
    box-sizing: border-box;
    margin-top: 14px;
    .item-tite {
      width: 18px;
      height: 20px;
      margin-right: 11px;
    }
  }
  .btn {
    width: 528px;
    height: 48px;
    background-image: linear-gradient(180deg, #406bff 0%, #5f2eff 100%);
    border-radius: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 16px;
    color: #fafbfd;
  }
  .item-bottom {
    width: 528px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 13px;
    color: #999999;
    padding-left: 21px;
    span {
      color: #ea4f29;
      font-size: 13px;
    }
  }
}
</style>
