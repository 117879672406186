<template>
  <div class="portrait">
    <div class="portrait-top d-flex">
      <div class="left">
        <div class="title">推荐头像</div>
        <ul class="left-list d-flex flex-wrap flex-column justify-between">
          <li
            v-for="item in pictureList"
            :key="item"
            @click="selectPicture(item)"
          >
            <img :src="item" alt="" />
          </li>
        </ul>
      </div>
      <div class="right">
        <div class="title">选择上传方式</div>
        <div class="btn cursor">
          <el-upload
            class="avatar-uploader"
            action="https://mvpaaa.com:9000/Service-Online/api/online/file"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
          >
            <img src="../../../assets/img/shangchuan.png" alt="" />
          </el-upload>
        </div>
        <div class="txt">
          仅支持JPG、GIF、PNG格式，文件小于5M。（使用高质量图片，可生成高清头像）
        </div>
        <div class="img">
          <img v-if="imageUrl" :src="imageUrl" />
        </div>
      </div>
    </div>
    <div class="preview">
      <div class="preview-title">效果预览</div>
      <div class="preview-box d-flex align-end">
        <div class="preview-left d-flex align-center flex-column">
          <div class="img">
            <img v-if="url != ''" :src="url" alt="" />
            <img v-if="url == ''" src="../../../assets/img/big.png" alt="" />
          </div>
          <div class="size">180*180像素</div>
        </div>
        <div class="preview-right d-flex align-center flex-column">
          <div class="img">
            <img v-if="url != ''" :src="url" alt="" />
            <img v-if="url == ''" src="../../../assets/img/small.png" alt="" />
          </div>
          <div class="size">50*50像素</div>
        </div>
      </div>
    </div>
    <div
      class="btn-b d-flex align-center justify-center cursor"
      @click="submit()"
    >
      保存
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pictureList: [
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang1.png",
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang2.png",
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang3.png",
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang4.png",
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang5.png",
        " https://mvpaaa.com:9000/Service-Online/upload/image/touxiang6.png",
      ],
      url: "",
      imageUrl: ""
    };
  },
  mounted() {
    // this.getInit();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      let _this = this
      console.log(res, file.raw)
      _this.imageUrl = ''
      _this.url = ''
      _this.imageUrl = URL.createObjectURL(file.raw);
      _this.imageUrl = res.data;
      _this.url = res.data;
    },

    selectPicture(url) {
      this.imageUrl = ''
      this.url = url;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message({
          type: "error",
          message: "上传头像图片大小不能超过 2MB!",
          showClose: true,
          offset: 200,
          duration: 1000
        });
      }
      return isLt2M;
    },
    async savePicture(params) {
      let _this = this;
      let data = {
        iconPath: _this.url
      }
      const res = await this.$ajaxRequest('put', 'savePicture', data)
      console.log(res);
      if (res.code == 200) {
        let user = this.$store.state.user;
        console.log(user);
        user.icon = _this.url;
        this.$store.commit("userInfo", user);
        this.$message({
          message: res.msg,
          type: "SUCCESS"
        });
      }

    },
    submit() {
      let _this = this;
      if (_this.url == "") {
        this.$message({
          message: "请选择图片",
          type: "error"
        });
      } else {
        _this.savePicture()
      }
    }
  }
};
</script>

<style lang="less" scoped>
.portrait {
  height: 614px;
  background: #fbfbfb;
  border-top: 1px solid #5f2fff;
  padding: 28px 0;
  box-sizing: border-box;
  .portrait-top {
    .left {
      width: 398px;
      padding-left: 38px;
      box-sizing: border-box;
      .title {
        font-size: 14px;
        color: #333333;
      }
      .left-list {
        height: 250px;
        border-right: 1px solid #eeeeee;
        padding: 20px 21px 29px 0;
        box-sizing: border-box;
        li {
          height: 80px;
          width: 80px;
          margin-right: 33px;
        }
      }
    }
    .right {
      margin-left: 53px;
      .title {
        font-size: 14px;
        color: #333333;
        margin-bottom: 18px;
      }
      .btn {
        height: 32px;
        width: 98px;
        margin-bottom: 11px;
      }
      .txt {
        font-size: 12px;
        color: #999999;
        margin-bottom: 11px;
      }
      .img {
        height: 141px;
        width: 141px;
      }
    }
  }
  .preview {
    // width: 301px;
    margin-left: 243px;
    margin-top: 46px;
    .preview-title {
      font-size: 14px;
      color: #333333;
    }
    .preview-box {
      margin-top: 24px;
      .preview-left {
        margin-right: 121px;
        .img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          overflow: hidden;
        }
        .size {
          font-size: 12px;
          color: #999999;
          margin-top: 13px;
        }
      }
      .preview-right {
        .img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          overflow: hidden;
        }
        .size {
          font-size: 12px;
          color: #999999;
          margin-top: 13px;
        }
      }
    }
  }
  .btn-b {
    width: 528px;
    height: 48px;
    background-image: linear-gradient(180deg, #406bff 0%, #5f2eff 100%);
    border-radius: 24px;
    margin: 0 auto;
    box-sizing: border-box;
    margin-top: 16px;
    font-size: 16px;
    color: #fafbfd;
  }
}
</style>
